<template>
	<div class="clients">
		<h1>Clients</h1>
		<!-- GET CLIENT FORM -->
		<AddClientForm :key="clientsListKey" @newClientAdded="newClientAdded" @formOpened="formOpened"></AddClientForm>
        <p v-for="message in formMessages" :key="message">
            {{message}}
        </p>
		<!-- GET MAIN CLIENTS LIST -->
		<ClientsList :key="clientsListKey"></ClientsList>
	</div>
</template>
<script>
import AddClientForm from "../../components/forms/AddClientForm.vue"
import ClientsList from "../../components/lists/ClientsList.vue"

export default {
	data() {
		return {
			clientsListKey: 1,
            formMessages: [],
		}
	},
	components: {
		AddClientForm,
		ClientsList,
	},
	methods: {
        formOpened(){
            this.formMessages = [];
        },
        newClientAdded(messages){
            // console.log(messages);
            this.formMessages = messages;
            this.refreshClientsList();
        },
		refreshClientsList() {
			this.clientsListKey++
		},
	},
}
</script>
